import React, { useCallback, useMemo, useRef } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  Accordion,
  Box,
  Button,
  Collapse,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  List,
} from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { useComposable } from 'composable';
import { ChildrenMenuItem } from 'composable/components/cms-components/left-side-menu/children-menu-item';
import { MenuItem } from 'composable/components/cms-components/left-side-menu/menu-item';
import { Nav } from 'composable/components/cms-components/left-side-menu/nav';
import { ShopCategories } from 'composable/components/cms-components/shop-categories-left-side-menu';
import { FEATURE_FLAGS } from 'composable/components/general';
import { Logo } from 'composable/components/logo';
import { PayBillButton } from 'composable/components/pay-bill-button';
import { usePropsMenu } from 'composable/helpers/hooks/usePropsMenu';
import { SideMenuItem } from 'composable/types';
import { useFormat } from 'helpers/hooks';
import { isPublicSlug, isSuperUserPage as isSuperUserPageCb } from 'helpers/slugHelpers';
import { ShoppingCategoriesOpenProps } from 'hooks/global/use_privateCheckoutGlobal/types';
import { userLogout } from 'hooks/global/use_privateUserGlobal/utils';
import { AccordionLink } from 'frontastic/tastics/composable/mega-menu-three-levels-2';
import { ButtonLinks } from './button-links';
import { useHandleHeightShift } from './hooks/use-handle-height-shift';
import { SideBarAccordionItem } from './sidebar-accordion-item';
import { sanitizeLabel, selectedItemCb } from './utils';
import { LOGOUT_CALLERS } from 'hooks/global/use_privateUserGlobal/constants';

export interface LeftSideMenuProps {
  links: SideMenuItem[];
  accordionLinks?: AccordionLink[];
}

//Constants
//TODO - Move to constants file and check references in other components
export const leftSideMenuWidth = '251px';
export enum LeftSideMenuLabels {
  MyShamrock = 'MyShamrock',
}
export enum LeftSideMenuTypes {
  Default = 'default',
  OrderGuides = 'orderGuidesPage',
  Catalog = 'catalogPage',
  Accounts = 'accountsPage',
  PayBill = 'payBillButton',
  MyShamrock = 'myShamrock',
  ShamrockORDERS = 'shamrockOrders',
  OgInternalLinks = 'ogInternalLinks',
  ShopCategories = 'shopCategoriesPage',
}
export const excludedLabesOpenNewWindow = [
  LeftSideMenuTypes.ShamrockORDERS,
  LeftSideMenuTypes.MyShamrock,
  LeftSideMenuTypes.PayBill,
];
export const excludedShopCategoriesLabel = [LeftSideMenuTypes.ShopCategories];
export const excludedLabels = [...excludedLabesOpenNewWindow, ...excludedShopCategoriesLabel];
// Buttons that are displayed in public pages

export const LeftSideMenu = ({ links, accordionLinks }: LeftSideMenuProps) => {
  const { shoppingCategoriesOpen, closeShoppingCategories, openShoppingCategories } = useGlobal().useCheckoutGlobal;

  const { formatMessage } = useFormat({ name: 'common' });
  const listRef = useRef(null);
  const { difference } = useHandleHeightShift(listRef);

  const router = useRouter();
  const { megaDrawer } = useComposable();

  const { openItems, setOpenItems, buttons, finalLinks } = usePropsMenu(links);
  // Set selected item based on URL
  const selectedItem = useMemo(() => selectedItemCb(router.asPath), [router.asPath]);

  const toggleOpen = useCallback((item: SideMenuItem) => {
    setOpenItems((prev) => {
      if (item.menuType === LeftSideMenuTypes.OrderGuides && prev[item.menuType]) {
        return { ...prev };
      }
      return { ...prev, [item.menuType]: !prev[item.menuType] };
    });
  }, []);

  const isSelected = useCallback(
    (path: string) => {
      return path === selectedItem;
    },
    [selectedItem],
  );

  const handleLogout = async () => {
    await userLogout(true, { caller: LOGOUT_CALLERS.HARD_LOGOUT_FROM_LEFT_SIDE });
  };

  const slug = router.asPath;
  const isPublicPage = isPublicSlug(slug);
  const isSuperUserPage = isSuperUserPageCb(slug);

  const showAccordionLinks = FEATURE_FLAGS.SIDEBAR_CHANGES && accordionLinks && accordionLinks.length > 0;

  return (
    <>
      <Drawer
        placement="left"
        onClose={closeShoppingCategories}
        isOpen={shoppingCategoriesOpen === ShoppingCategoriesOpenProps.LEFT_SIDE}
      >
        <DrawerOverlay css={{ background: 'rgba(0, 0, 0, .7)' }} />
        <DrawerContent
          minW={{ base: '339px', lg: '400px' }}
          maxW={{ base: '339px', lg: '400px' }}
          style={ShopCategories.DrawerStyle}
          {...ShopCategories.DrawerCSS}
        >
          <ShopCategories />
        </DrawerContent>
      </Drawer>

      <Nav leftSideMenuWidth={leftSideMenuWidth}>
        {/* Shamrock Logo */}
        {FEATURE_FLAGS.HEADER_CHANGES && (
          <Flex px={10} paddingTop={5} paddingBottom={12}>
            {isPublicPage || isSuperUserPage ? (
              <Logo
                isHideOnPage={false}
                imageProps={{
                  transform: 'translateY(0px)',
                  w: { base: '64px', xl: '94px' },
                  h: { base: '29px', xl: '43px' },
                  cursor: 'default',
                }}
              />
            ) : (
              <Link
                tabIndex={0}
                as={NextLink}
                href="/"
                passHref
                pointerEvents="all"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    router.push('/');
                  }
                }}
                _focusVisible={{
                  outlineColor: 'violet.400',
                  outlineWidth: '2px',
                  outlineStyle: 'solid',
                  borderRadius: '4px',
                  boxShadow: 'none',
                }}
              >
                <Logo
                  isHideOnPage={false}
                  imageProps={{
                    transform: 'translateY(0px)',
                    w: { base: '64px', xl: '94px' },
                    h: { base: '29px', xl: '43px' },
                  }}
                />
              </Link>
            )}
          </Flex>
        )}

        <ButtonLinks links={buttons} />
        <List ref={listRef}>
          {finalLinks.map((item: SideMenuItem) => {
            switch (item.menuType) {
              case LeftSideMenuTypes.PayBill:
                return <PayBillButton title={item.label} key={`payBillButton_${item.label}`} />;
              default:
                return (
                  <React.Fragment key={item.menuType}>
                    <MenuItem
                      item={item}
                      locale={router.locale || router.defaultLocale}
                      onClick={() => {
                        if (excludedLabesOpenNewWindow.includes(item.menuType as LeftSideMenuTypes)) {
                          window.open(item.link.type === 'link' ? item.link.link : item.link.pageFolder?._url);
                          return;
                        }
                        if (item.menuType === LeftSideMenuTypes.Accounts) {
                          item.children && toggleOpen(item);
                          return;
                        }

                        item.children && toggleOpen(item);
                      }}
                      isAccountOpened={
                        item.menuType === LeftSideMenuTypes.Accounts &&
                        openItems.hasOwnProperty(LeftSideMenuTypes.Accounts) &&
                        openItems[LeftSideMenuTypes.Accounts] === true
                      }
                    />
                    {item.children && (
                      <Box as="li">
                        <Collapse in={openItems[item.menuType]}>
                          <List>
                            {item.children.map((child) => (
                              <ChildrenMenuItem
                                item={child}
                                key={sanitizeLabel(child.label)}
                                isSelected={isSelected}
                                onClick={() => {
                                  if (child.menuType === LeftSideMenuTypes.ShopCategories) {
                                    openShoppingCategories(
                                      megaDrawer.isOpen
                                        ? ShoppingCategoriesOpenProps.DRAWER
                                        : ShoppingCategoriesOpenProps.LEFT_SIDE,
                                    );
                                    return;
                                  }
                                }}
                              />
                            ))}
                          </List>
                        </Collapse>
                      </Box>
                    )}
                  </React.Fragment>
                );
            }
          })}
        </List>

        {/* Accordions */}
        {showAccordionLinks && (
          <Flex mt="auto" display="column" transform={`translateY(-${difference})`} px={6}>
            <Accordion allowToggle mt="50px">
              {accordionLinks.map((accordionLink) => (
                <SideBarAccordionItem
                  key={accordionLink.title}
                  title={accordionLink.title}
                  accordionLink={accordionLink.children}
                />
              ))}
            </Accordion>

            {/* LogoutButton */}
            {!isPublicPage && (
              <Button px={3} height={8} variant="ds-filled" mt={4} mb={6} ml={4} fontSize="sm" onClick={handleLogout}>
                {formatMessage({ id: 'action.logout' })}
              </Button>
            )}
          </Flex>
        )}
      </Nav>
    </>
  );
};
